module.exports = [{
      plugin: require('../node_modules/gatsby-theme-localization/gatsby-browser.js'),
      options: {"plugins":[],"languages":["en","es"],"localesDir":"./src/locales","allowIndex":false,"defaultLng":"en","i18next":{"fallbackLng":"en","debug":false},"i18nPlugin":{"langKeyDefault":"en","useLangKeyLayout":false}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Resume","short_name":"Landing","start_url":"/gatsby-starter-resume/","background_color":"#663399","theme_color":"#663399","display":"standalone","icon":"src/assets/img/website-icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"34ded252fc8e8dadced26fc66fa517fe"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
